<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
      <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input placeholder="请输入内容" v-model="getInfo.info">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
        <el-col :span="8"> </el-col>
        <el-tooltip
          class="item"
          effect="dark"
          content="增加站点"
          placement="top-start"
        >
          <el-button  type="primary" @click="addPeoBtn">增加站点</el-button>
        </el-tooltip>
      </el-row>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        v-if="tableData"
      >
            <el-table-column
          align="center"
          prop="site_name"
          label="名称"
        >
        </el-table-column>
            <el-table-column
          align="center"
          prop="site_name"
          label="图片"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="site_content"
          label="内容"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="latitude"
          label="经度"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="longitude"
          label="维度"
        >
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="查看站点详情"
              placement="top-start"
            >
              <el-button
                icon="el-icon-search"
                @click="lookSite(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="修改站点"
              placement="top-start"
            >
              <el-button
                icon="el-icon-edit"
                type="primary"
                @click="updataPicture(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除站点"
              placement="top-start"
            >
              <el-button
                icon="el-icon-delete"
                type="danger"
                @click="delPicture(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="pageChange"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.page_num"
        background
        :total="exp.num"
      >
      </el-pagination>
    </el-card>
     <!-- 增加场地服务标题 -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      @close="userClose(form)"
      :append-to-body="true"
       
    >
    <div slot="title" v-if="updataFalg" class="dialog-footer" >
        <div class="title" >修改人数</div>
      </div>
       <div slot="title" v-else-if="lookFalg" class="dialog-footer" >
        <div class="title" >查看人数</div>
      </div>
      <div slot="title" v-else class="dialog-footer" >
        <div class="title" >增加人数</div>
      </div>
      <div class="diaBox">
      <el-form :model="form" ref="ruleForm"  :rules="rules">
                       <el-form-item label="人数" :label-width="formLabelWidth" prop="item">
                                 <el-input v-model="form.item"></el-input>
  </el-form-item> 
      </el-form>
       </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-if="addFlag" type="primary" @click="addArea('ruleForm')"
          >添 加</el-button
        >
        <el-button v-else-if="lookFalg" type="primary" @click="dialogFormVisible = false"
          >确 认</el-button
        >
       <el-button v-else type="primary" @click="updSiteSer('ruleForm')"
          >修 改</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {PostSite_listdata} from "@/api/index.js"

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
        var validateItem = (rule, value, callback) => {
        if (this.form.item === '') {
          callback(new Error('请输入人数'));
        } else {
          callback();
        }
      };
    //这里存放数据
    return {
         rules: {
          item: [
            { validator: validateItem, trigger: 'blur' }
          ],
          
        },
      //总条数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: "",
        page_code: 1,
        page_num: 15,
      },
      form:{
        item:''
      },
      props:{
        children:'sub',
        value:'id',
        label:'name'
      },
      //表格数据
         tableData: [],
            token: {
        token: window.sessionStorage.getItem("token"),
      },
      formLabelWidth: "100px",
       imgParam: {
        path: "contract/template",
      },
        fileList: [],
        //控制弹窗--新增修改
      dialogFormVisible: false,
      addFlag:false,
      lookFalg:false,
      updataFalg:false,
       options: [],
         options1: [{
          value: 0,
          label: '上门服务模板'
        }, {
          value: 1,
          label: '场地预约模板'
        }],
    };
  },
  //监听属性 类似于data概念
  computed: {
        imgUrl() {
      return this.$store.state.imgUrl;
    },
  },
  //监控data中的数据变化
  watch: {
           dialogFormVisible(newVal, oldVal) {
      if (!newVal) {
        this.form = this.$options.data().form;
        this.updataFalg=false;
        this.lookFalg=false;
        this.addFlag=false;
      }
    },
  },
  //方法集合
  methods: {
    //增加站点
    addPeoBtn(){

    },
    //查看站点
    lookSite(){
        
    },
    //修改每页数量
    sizeChange(value) {
      this.getInfo.page_num = value;
      var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
      };
      console.log(data);
      this.tableData = [];
     // this.getPeopleList(data);
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value;
      var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
      };
      console.log(data);
      this.tableData = [];
    //  this.getPeopleList(data);
    },
          exceed() {
      this.$message({
        message: "数量超出",
        type: "warning",
      });
    },
    //导入图片事件
    imgSuccess(response, file, fileList) {
        // console.log(response)
      this.form.url = response.data.url;
    },

    //移除图片的事件
    imgRemove(file, fileList) {
      this.form.url = "";
    },
           //添加事件 退出弹窗
    userClose(e) {
      this.form = {
        url:'',//图片
        type:'',//空间区域0室内  1室外
      };
      (this.fileList = []), console.log("close");
    },
    /**-----------------网络请求生命周期函数--------------------- */
     //站点列表
    async PostSiteList(data){
        let res=await PostSite_listdata(data);
        console.log(res.data)
        this.tableData=res.data.result.data;
        this.getInfo={
            page_code:res.data.result.last_page,
            list_rows:res.data.result.per_page
        }
          this.exp.num=res.data.result.total;
        //   this.dialogFormVisible=false;
        // console.log(res.data.data);
    },
  },

  
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // var data = {
    //   page: this.getInfo.page_code,
    //   list_rows: this.getInfo.page_num,
    // };
     //this.PostSiteList();
  },

  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
.title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.diaBox {
  display: flex;
  justify-content: center;
}
.imgUrl {
  margin-left: 50%;
  transform: translateX(-50%);
  width: 250px;
  height: 150px;
  overflow: hidden;
  text-align: center;
}
</style>